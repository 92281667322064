
          @import "src/assets/scss/_shared.scss";
        
.proCard {
  width: 100%;
  padding: 1rem 0;
  .avatar {
    border-radius: 50%;
    width: 106px;
    height: 106px;
    border: 1px solid #707070;
  }
  border-bottom: 1px solid #c3c3c3;
  .btn-main {
    height: 53px;
  }
  @media (max-width: 430px) {
    & {
      flex-direction: column !important;
    }
    .btn-main {
      margin: 1rem 0;
    }
  }
}
