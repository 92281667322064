
          @import "src/assets/scss/_shared.scss";
        
.profile-settings-modal {
	.btn {
		background-color: #12819c;
		border-color: #12819c;
		border-radius: 0;
		color: #fff;
		line-height: 1;
		text-align: center;

		&:hover {
			background-color: #12819c;
			border-color: #12819c;
			color: #fff;
		}
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
		appearance: textfield;
	}
	// Spinner
	.ant-modal-body {
		position: relative;
		.spinner {
			&__container {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background: rgba($main-color, 0.15);
				.spinner {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
}

@include to-mobile-sm {
	.profile-settings-modal {
		.ant-form .ant-form-item {
			flex: 1 !important;
			flex-direction: row;
			flex-wrap: wrap !important;
		}
		// input {
		// flex: 1 !important;
		// }
		// .ant-form-item .ant-form-item-label {
		// 	flex: 1 !important;
		// }
	}
}
