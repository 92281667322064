
          @import "src/assets/scss/_shared.scss";
        
projects-room-component .projects__room {
	.projects-room {
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	.new-project__service--checkbox {
		font-size: 1rem;
		color: $black-color;
		sup {
			font-size: 0.75rem;
			font-weight: bold;
			// used color
			color: red;
		}
	}
	.new-project__service--type {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		padding: 0;
		li {
			.ant-checkbox-wrapper {
				color: $gray70-color;
			}
			.ant-checkbox-inner {
				width: 18px;
				height: 18px;
			}
		}
		.ant-checkbox-checked::after {
			border: 0 !important;
		}
	}
}

@include to-tablet-sm {
	.hide {
		display: none;
	}
}
@include to-mobile-sm {
	.submitted .porperties {
		.button {
			width: 100%;
		}
	}
}
@include to-desktop-sm {
	.new-project-btn,
	.button {
		width: 90%;
	}
}
.no-projects {
	h3 {
		color: $gray70-color;
		font-weight: 600;
	}
}

.projects-room-component {
	button#rc-tabs-3-more {
		display: none;
	}
	.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
		display: none !important;
	}
	.projects-name {
		.ant-tabs-tab-btn {
			font-size: 1.0625rem;
		}
	}
	.ant-tabs-tab-btn {
		font-size: 1rem;
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: $main-color !important;
		font-size: 1.063rem;
	}
}
