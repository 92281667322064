
          @import "src/assets/scss/_shared.scss";
        
.new-project {
  .project-name{
    max-width: 80vw;
  }
  .new-project__service--type {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    li {
      .ant-checkbox-wrapper {
        color: $gray70-color;
      }
      .ant-checkbox-inner {
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }
    }
    .ant-checkbox-checked::after {
      border: 0 !important;
    }
  }
  .new-project--watermarks {
    color: $gray89-color;
    font-size: 0.8rem;
  }

  .add__project-uplaod {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .cover {
    position: relative;
    z-index: 999;
  }
  .cover__title {
    color: $gray70-color;
    .ant-form label {
      margin-top: 1rem !important;
      border: 1px solid red;
    }
  }
  .cover__controls {
    position: absolute;
    top: 3%;
    left: 5%;
    width: 90%;
    .cover__controls-ham {
      width: 20px;
      height: 3px;
      background-color: $main-color;
      margin-bottom: 2px;
    }
    .cover__controls-close {
      cursor: pointer;
      color: $main-color;
    }
  }
  .ant-switch-checked {
    background: $main-color;
  }
  .add__projectbutton {
    button {
      background-color: $main-color;
      color: #fff;
      padding: 0.8rem 2rem;
      border-radius: 0;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .ant-picker,
  .ant-picker-focused,
  .ant-picker:hover {
    border-color: $main-color !important;
  }
  .ant-upload-list {
    display: none;
  }
}

@include to-tablet-sm {
  .covers-container {
    grid-auto-rows: minmax(160px, auto) !important;
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .new-project--date {
    flex-direction: column;
    .ant-picker {
      margin-bottom: 15px !important;
    }
  }
  .new-project {
    .new-project__service--type {
      .ant-radio-wrapper {
        width:100%;
        margin-block: 0.25rem;
      }
    }
    
    
    
    }
}

@include to-mobile-sm {
  .buttons {
    flex-direction: column;
    button {
      margin-bottom: 10px;
      width: 100%;
    }
  }
  .new-project {
    .new-project__service--type {
      width: 100% !important;  
      .ant-radio-wrapper {
        width: 100%;
        margin-block: 0.25rem;
      }
    }
    .ant-space-item {
      width: 100% !important;
    }
    .ant-row.ant-row-rtl.ant-form-item.form__item {
      width: 100% !important;
    }
    .ant-space {
      width: 100% !important;
    }


    // .ant-space-item {
    //   width: 100% !important;
    //   .ant-row {
    //     width: 100%;
    //     .form__item {
    //       border: 1px solid red;
    //     }
    //   }
    // }
  }
}
