$main-color: #12819c;
$black-color: #1c2126;

// gray(s)
$gray1a-color: #0000001a;
$graycf-color: #cfcfcf;
$gray70-color: #707070;
$gray89-color: #897f7f;
$gray3d-color: #3d3d3d;

// box-shadow
$box-shadow: 0px 3px 6px #00000029;
$border: 1px solid $gray70-color;

// font-weight
$normal: 400;
$bold: 600;
$bolder: 700;
