
          @import "src/assets/scss/_shared.scss";
        
.utility-modal {
	.ant-modal-content {
		height: 70vh;
	}
	.ant-modal-body {
		background-color: white;
	}
}
