
          @import "src/assets/scss/_shared.scss";
        
.project__room-filters {
  > div {
    box-shadow: $box-shadow;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    padding: 15px 10px;

    .delete {
      cursor: pointer;
    }

    .project-title {
      cursor: pointer;
      max-width: fit-content;
    }
  }

  .project__room-status {
    font-size: 1rem;
    color: #897f7f;
  }

  .project__info {
    .date-text,
    .coins-text {
      font-size: 1.25rem;
      color: #707070;
    }
  }
}
