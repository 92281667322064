
          @import "src/assets/scss/_shared.scss";
        
// clients-reviews__rating-dropdown
.rating-review {
	display: flex;
	&__row {
		flex: 1;
	}
	.main-reviews {
		border-inline-end: 1px solid $gray70-color;
		padding-inline-end: 23px;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&__list {
			.item {
				margin-top: 0.5rem;
				margin-bottom: 0.5rem;
				h6 {
					font-size: 0.85rem;
					font-weight: bold;
					color: $gray70-color;
					text-align: center;
				}
				.ratingModal {
					margin-top: -1.25rem;
				}
			}
		}

		&__p {
			text-align: center;
			color: $main-color;
			font-size: 0.85rem;
			margin-bottom: 2rem !important;
		}

		&__figure {
			text-align: center;
			margin: 0 auto;
			width: 80%;
			img {
				width: 100%;
			}
		}
	}

	.clients-reviews {
		padding-inline-start: 0.25rem;
		padding-inline-end: 0.25rem;

		&__wrapper {
			flex: 1;
			&.no-list {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		&__list {
			.item {
				margin-bottom: 1.5rem;
				padding: 0.75rem;
				box-shadow: $box-shadow;
			}
		}

		&__rating {
			display: flex;
			justify-content: space-between;
			align-items: center;

			&-dropdown {
				text-align: center;
				svg {
					color: #c1c1c1;
				}
				.item {
					padding-bottom: 0.25rem;
					margin-right: auto;
					margin-left: auto;
				}
			}
		}

		&__date {
			margin-inline-end: 1rem;
			font-size: 0.75rem;
			line-height: 3;
			color: $gray70-color;
		}

		&__header {
			h4 {
				font-size: 1rem;
				margin-bottom: 0.5rem;
				color: $black-color;
				font-weight: $bolder;
			}
		}

		&__desc {
			font-size: 0.9rem;
			font-weight: $normal;
			color: $black-color;
			margin-bottom: 1rem !important;
		}

		&__footer {
			p {
				font-weight: bold;
				font-size: 0.75rem;
				color: $gray70-color;
			}
		}
	}
}

@include to-tablet-sm {
	.rating-review {
		.main-reviews {
			border-right: none;
			flex: 1;

			&__list {
				margin-top: 1rem;
				margin-bottom: 1rem;
				text-align: center;
			}

			&__p {
				text-align: center;
				color: $main-color;
				font-size: 0.85rem;
				margin-bottom: 2rem !important;
			}

			&__figure {
				text-align: center;
				margin: 0 auto;
				width: 40%;

				img {
					width: 100%;
				}
			}
		}

		.clients-reviews {
			&__list {
				margin-top: 2rem;
				padding-top: 2rem;
				border-top: 1.5px solid #c1c1c1;
			}
			&__rating {
				align-items: center;
				justify-content: space-between;
				& > .ant-rate {
					flex: 1;
					.ant-rate-star:not(:last-child) {
						margin-right: 0.25rem;
					}
				}
				&-dropdown {
					&-wrapper {
						justify-content: space-between;
						// width: 100%;
					}
				}
			}
		}
	}
	html.ar {
		.clients-reviews {
			&__rating {
				& > .ant-rate {
					flex: 1;
					border: 1px solid red;
					.ant-rate-star:not(:last-child) {
						margin-left: 0.25rem;
						margin-right: 0;
					}
				}
			}
		}
	}
}

.clientView {
	.rating-review {
		box-shadow: none;
	}
}

@include to-tablet-sm {
	.rating-review .clients-reviews__date {
		margin-inline-end: 0;
	}
	.rating-review {
		.main-reviews {
			border-inline-end: none;
			padding-inline-end: 0;
		}
	}
}
