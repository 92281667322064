
          @import "src/assets/scss/_shared.scss";
        
body {
	overflow-x: hidden;
}
.home {
	&__section {
		padding-block: 3.875rem;
		@include to-mobile-sm {
			& {
				padding-block: 1rem;
			}
		}
	}

	&__header {
		h2 {
			font-weight: bold;
			font-size: 1.75rem;
			color: $main-color;
			margin-bottom: 1rem;
		}
	}

	&__footer {
		color: white;
		font-size: 1.25rem;
		background: $main-color;
		padding-bottom: 1rem;
		.footer-container {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1rem;
			p:first-of-type {
				margin-inline-end: 3rem;
			}
		}
	}
}

@include to-tablet-lg {
	.home {
		&__footer {
			.footer-container {
				flex-direction: column;
				p:first-of-type {
					margin-inline-end: unset;
					margin-bottom: 2rem;
				}
			}
		}
	}
}
