
          @import "src/assets/scss/_shared.scss";
        
.serviceType-page {
	.serviceType {
		&__specialization {
			font-size: 1rem;
			font-weight: bold;
			color: $black-color;
			span.ant-radio + * {
				font-size: 1.15rem;
				font-weight: 400;
			}
		}
		&__specialization,
		&__type,
		&-group {
			margin-bottom: 2rem;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		&__type {
			.ant-radio + span {
				font-size: 1rem;
				font-weight: 700;
			}
		}
		&-group {
			.item {
				span {
					color: $gray70-color;
					font-size: 1rem;
					font-weight: $normal;
				}
			}

			&__header {
				h3 {
					color: $main-color;
					font-size: 1.5rem;
					font-weight: 600;
					margin-bottom: 1.2rem;
				}
			}
		}
		&__footer {
			p {
				text-align: center;
				font-size: 1rem;
				font-weight: $normal;
				color: $gray70-color;
				margin-bottom: 1.25rem !important;
			}
		}
	}
	.other-input.ant-input {
		max-width: 150px;
	}

	.custom-checkbox-wrap-other {
		display: flex;
		align-items: center;
		label {
			display: flex;
			align-items: center;
			color: #707070;
			font-size: 1rem;
			font-weight: 400;
		}
	}

	.disclaimer {
		color: #897f7f;
		font-size: 0.85rem;
		text-align: center;

		&-wrapper {
			margin-top: 2rem;
			padding-block: 1rem 2rem;
			margin-inline: 4rem;
			position: relative;
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 50%;
				width: 57%;
				background: #848484;
				height: 1px;
				transform: translate(-50%);
			}
		}
	}
}

.ant-radio-checked + span,
.ant-radio-checked + span .other-span {
	color: $main-color !important;
	font-weight: 600 !important;
	transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
}

.ant-input:focus,
.ant-input-focused {
	box-shadow: none !important;
	border-color: $main-color !important;
}

.custom-checkbox {
	label {
		color: #707070;
		font-size: 1rem;
		font-weight: 400;
	}
	input {
		appearance: none;
		-webkit-appearance: none;
		height: 0.7em;
		width: 0.7em;
		padding: 0.5rem !important;
		background: transparent;
		border: 1px solid #bdbdbd;
		border-radius: 2px;
		margin-inline-end: 1rem;
		position: relative;
		text-align: center;

		&::after {
			font-family: "Font Awesome 5 Free";
			font-weight: 400;
			content: "✓";
			color: white;
			background: $main-color;
			position: absolute;
			line-height: 1;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			opacity: 0;
			transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
		}
		&:checked {
			border-color: $main-color;
			&::after {
				opacity: 1;
			}
		}
	}
}

@include to-tablet-sm {
	.serviceType-page {
		.disclaimer {
			&-wrapper {
				margin-inline: 1rem;
				line-height: 1.4;
				padding-top: 1rem;
			}
		}
		.serviceType {
			&__specialization {
				span.ant-radio + * {
					margin-bottom: 1rem;
					margin-inline-end: 0.75rem;
				}
			}
		}
	}
}
