
          @import "src/assets/scss/_shared.scss";
        
.pro-profile {
	.shortlisted {
		li {
			padding-bottom: 10px;
		}

		.pro__profile--img {
			figure {
				margin-right: auto;
				margin-left: auto;
				width: 120px;
				height: 120px;
				border-radius: 50%;
				overflow: hidden;
				border: 0.0625rem solid $gray70-color;
			}
		}
		.pro__profile--details {
			display: flex;
			flex-direction: column;
			h4 {
				margin-bottom: 0.5rem !important;
				color: $main-color;
				font-weight: bold;
			}

			svg {
				width: 1.25rem;
				height: 1.25rem;
			}
			p {
				font-size: 14.4px;
			}
		}
		.button {
			background-color: $main-color;
			color: #fff;
			border-radius: 0 !important;
			padding: 0.625rem 1.5625rem;
			width: 11rem;
			&:focus {
				box-shadow: none;
			}

			&.bordred-button {
				background: transparent;
				color: $main-color;
				border: 1px solid $main-color;
			}
		}
	}
	.empty_icon {
		path {
			stroke: #707070;
		}
	}
	h4 {
		font-size: 1.12rem;
		font-weight: bold;
		margin-bottom: 1.75rem !important;
	}
}
.emptyState {
	margin: 1rem;
	color: #12819c;
	font-size: 1rem;
	font-weight: bold;
	box-shadow: $box-shadow;
	padding: 0.5rem;
	text-align: center;
}
.reQuotation {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;

	&__p {
		font-size: 1rem;
		font-weight: bold;
		margin-bottom: 1.5rem !important;
	}
	&__btns {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.cancel {
		color: $main-color !important;
		border-color: $main-color !important;
		margin-inline-end: 3rem;
		border-width: 1.5px;
	}
}

@include to-tablet-lg {
	.reQuotation {
		width: 80%;
	}
}

@include to-mobile-sm {
	.pro-profile {
		.shortlisted {
			& > li {
				border-bottom: 0.0625rem solid $gray70-color;
				.pro__profile {
					flex-direction: column;
					figure {
						margin-inline: 0 !important;
					}
					.pro__profile--img {
						align-self: flex-start;
						margin-bottom: 0.9375rem;
						width: 50% !important;
					}
					.pro-profile__button {
						margin-top: 0.9375rem;
						justify-content: flex-start !important;
					}
					.pro__profile--details,
					.pro-profile__button {
						width: 100%;
					}
				}
			}
		}
	}
	.reQuotation {
		&__btns {
			flex-direction: column !important;
			flex-wrap: wrap;
		}
		.cancel {
			margin-inline-end: 0;
		}
	}
}
