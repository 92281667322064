
          @import "src/assets/scss/_shared.scss";
        
.home {
	&__hero {
		height: 92vh;
		min-height: 400px;
		position: relative;

		&--content {
			max-width: 45%;
			p {
				font-size: 1rem;
				font-weight: 600;
				color: $black-color;
			}
		}

		&--header {
			h2 {
				font-size: 2.5rem;
				font-weight: bold;
				color: $black-color;
			}
		}

		&--video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-position: bottom;
			object-fit: cover;
			z-index: -1;
		}

		&--container {
			height: 100%;
			display: flex;
			align-items: center;
		}
	}
}

@include to-tablet-sm {
	.home {
		&__hero {
			&--content {
				max-width: 45%;
			}
			&--container {
				margin-top: 0;
			}
			&--header {
				h2 {
					font-size: 2rem;
				}
			}

			&--video {
				object-position: center;
			}
		}
	}
}

@include to-mobile-sm {
	.home {
		&__hero {
			&--content {
				max-width: 90%;
				flex-direction: column;
			}
			&--container {
				display: flex;
				align-items: flex-start !important;
			}
		}
	}
}
