
          @import "src/assets/scss/_shared.scss";
        
.home {
  &__how-it-work {
    header {
      h2 {
        margin-bottom: 1.5rem;
      }
    }
    &__video{
      video{
      width: 100vw;
      max-width: 100vw;
      height: auto;
      object-fit: cover;
      border: none !important;
      }
    }
  }
}
