
          @import "src/assets/scss/_shared.scss";
        
.article-details {
	box-shadow: $box-shadow;
	padding: 2rem;
	margin: 2rem;
	.ant-carousel .slick-dots li button {
		background-color: $main-color !important;
		height: 0.25rem;
	}
}

@include to-tablet-sm {
	.article-details {
		padding-inline: 0 !important;
		margin-inline: 0.5rem !important;
	}
}
