
          @import "src/assets/scss/_shared.scss";
        
.profile-page {
	flex: 1;
	background: #fbfbfb;
	&__container {
		flex: 1;
		padding-block-end: 1rem;
	}
}

.portofolio-dropdown {
	.ant-dropdown {
		background: white;
		z-index: 999;
	}
}

.content {
	.navigation {
		margin-bottom: 20px !important;
		.wrapper {
			background-color: transparent !important;
			max-width: 100%;
			.ant-tabs {
				overflow: auto !important;
			}
			.tabs-container {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				.download-project-images {
					position: absolute;
					top: 0.5em;
					right: 0.5em;
					label {
						cursor: pointer;
					}
					&:hover {
						color: $main-color !important;
					}
				}
			}
			.tabs-container.page-container {
				.ant-tabs-content-holder {
					padding: 0;
					margin: 0;
					box-shadow: none;
				}
				.ant-tabs {
					width: 100%;
				}
			}
			.ant-tabs-tab {
				&:first-child {
					padding-left: 0;
				}
			}
			.ant-tabs-content {
				flex: 1;
				height: 100%;
			}
			.ant-tabs-tab:hover {
				color: $main-color;
			}
			.ant-tabs-tab-active,
			.ant-tabs-tab-btn {
				font-size: 1rem !important;
				transition: none;
			}

			.ant-tabs-tabpane {
				display: flex;
				flex-direction: column;
				flex: 1;
			}
			.portofolio {
				.edit,
				.remove {
					cursor: pointer;
				}
				.delete-modal {
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: #000;
					opacity: 0.4;
				}
			}
		}

		.settings-modal {
			position: absolute;
			top: 100%;
			left: 2%;
			width: 95%;
			z-index: 10;
			background-color: #fff;
			box-shadow: $box-shadow;
			padding: 10px;
			.settings-modal__header {
				h4 {
					color: $main-color;
				}
				.album-close {
					color: $main-color;
					cursor: pointer;
				}
			}
			figure {
				img {
					margin-bottom: 11px;
				}
			}
			h5 {
				font-size: 0.9rem !important;
				color: $gray70-color;
			}
			.ant-switch-checked {
				background-color: $main-color;
			}
		}
		.card-text {
			font-size: 0.8rem;
		}
		a {
			display: inline-block;
		}
		.card-title.h5 {
			font-size: 1.5rem !important;
			color: #3d3d3d;
			font-weight: bold;
		}
	}
}
.settings-menu {
	padding-inline-start: 0.25rem;
	padding-inline-end: 0.25rem;
	box-shadow: $box-shadow;
	ul {
		width: 100px;
		text-align: center;
		li {
			font-size: 0.7rem;
			margin-bottom: 0.25rem;
			cursor: pointer;
			&:hover {
				color: $main-color;
			}
		}
	}
}

.nav-tab {
	> .ant-tabs-nav {
		font-weight: bold;
	}
	.ant-tabs-nav {
		&::before {
			border-bottom: none;
		}
		.ant-tabs-tab-active {
			color: $main-color;
			.ant-tabs-tab-btn {
				color: $main-color;
				&:hover {
					color: $main-color;
				}
			}
		}
		.ant-tabs-tab-remove:focus {
			color: $main-color !important;
		}
	}
}
.content {
	.ant-tabs-content-holder {
		display: flex;
		flex-direction: column;
		min-height: 483px;
		box-shadow: $box-shadow;
		margin: 0.5rem !important;
		padding: 0.5rem;
	}
	.ant-tabs-top > .ant-tabs-nav {
		margin-bottom: 0.5rem !important;
	}
}

.internal-tabs {
	width: 100%;
	.ant-tabs-nav-list {
		// display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
}

.EmptyDataText {
	color: #12819c;
	font-size: 17px;
	font-weight: 600;
	text-align: center;
	height: 100%;
}

.ant-tabs-nav {
	&::before {
		border-bottom: none !important;
	}
}
.ant-tabs-content-holder {
	.ant-tabs-nav-list {
		justify-content: space-between !important;
		flex-basis: 100%;
		.ant-tabs-tab.ant-tabs-tab-active {
			color: $main-color !important;
			.ant-tabs-tab-btn {
				color: $main-color !important;
				&:hover {
					color: $main-color !important;
				}
			}
		}
	}
}
form.edit-project-title {
	width: 100%;
	input {
		width: 100%;
		border: none;
		border-bottom: 1px solid $gray70-color;
		&:active,
		&:focus {
			outline: none;
		}
	}
	button {
		display: none;
	}
}

.ant-tabs-tab {
	background: none !important;
	border: 0 !important;
}
.ant-tabs-nav::before {
	z-index: 3;
}

.ant-col {
	display: flex !important;
	flex-direction: column;
}

// subnav
.sub-nav {
	padding-bottom: 1rem;
	.sub-nav__ul {
		display: flex;
	}
	.item {
		font-size: 0.9375rem;
		margin-inline-end: 1.35rem;
		font-weight: bold;
		color: #000;

		&:last-of-type {
			margin-inline-end: 0;
		}
		&:first-of-type {
			color: $main-color;
		}
	}
}

@include mobile-xs {
	.content .navigation .wrapper .tabs-container .download-project-images {
		top: 2.51rem;
	}
	.client-view {
		.content .ant-tabs-content-holder {
			margin-top: 3rem !important;
		}
	}
}

@include to-tablet-sm {
	.sub-nav {
		overflow: auto;
		.sub-nav__ul {
			width: 590px;
		}
		.item {
			font-size: 0.875rem;
		}
	}
}

@include desktop {
	.sub-nav {
		.sub-nav__ul {
			width: 590px;
		}
		.item {
			margin-inline-end: 1.2rem;
			font-weight: bold;
			font-size: 0.875rem;
		}
	}
}

html.ar {
	.download-project-images {
		left: 0.5em;
		right: auto !important;
	}
}
