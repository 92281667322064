
          @import "src/assets/scss/_shared.scss";
        
.project-modal {
	.item {
		position: relative;

		.pending-text {
			background: rgba(#333, 0.45);
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.25rem;
			font-weight: 400;
			z-index: 999;
		}

		figure {
			margin-bottom: 0.75rem !important;
			position: relative;
			.dot {
				position: absolute;
				top: 1rem;
				right: 1rem;
				width: 1rem;
				height: 1rem;
				border-radius: 50%;
				&.reject {
					background-color: #f00;
				}
				&.accept {
					background-color: green;
				}
			}
			img {
				width: 100%;
			}
		}
	}
	.project__el {
		font-size: 1rem;
		margin-bottom: 0.5rem;
	}

	.service {
		color: $main-color;
	}

	.project__images {
		height: 350px;
		overflow-y: auto;
		padding: 0.5rem;
	}
}
