
          @import "src/assets/scss/_shared.scss";
        
.profile__sidebar {
	text-align: center;
	box-shadow: $box-shadow;
	padding: 1rem;
	background-color: white;

	.clientView {
		h2 {
			font-size: 1.5rem;
			font-weight: bold;
			color: #707070;
			margin-bottom: 1rem;
			overflow-wrap: break-word;
		}

		.serviceType {
			font-size: 1rem;
			margin-bottom: 1rem;
			color: #707070;
		}
		.phone,
		.email,
		.address,
		.bio {
			font-size: 0.875rem;
			margin-bottom: 0.25rem;
			color: #707070;
			overflow-wrap: break-word;
		}
	}
	.ant-input-borderless {
		border-bottom: 1px solid $main-color !important;
		border-radius: 0;
	}

	.ant-input-affix-wrapper-borderless {
		border-bottom: 1px solid $main-color !important;
		border-radius: 0;
	}

	.sidebar {
		box-shadow: $box-shadow;
		padding-top: 1rem;
		padding-bottom: 1rem;
		text-align: center;

		&__figure {
			width: 157px;
			height: 157px;
			overflow: hidden;
			border-radius: 50%;
			margin: auto;
			margin-bottom: 1rem !important;
			border: $border;
			img {
				width: 100%;
			}
		}

		&__header {
			margin-bottom: 1rem;
			h2 {
				font-size: 1.25rem;
				font-weight: bold;
				color: $gray70-color;
				text-align: center;
			}
			p {
				font-size: 1rem;
				color: $gray70-color;
			}
			.edit-icon {
				cursor: pointer;
				color: $main-color;
			}
		}

		&__content {
			&__address {
				color: $main-color;
				font-weight: bold;
			}
			&--bio {
				font-size: 0.75rem;
				color: $black-color;
				margin-bottom: 1rem !important;
			}
			&--website {
				font-size: 1rem;
				font-weight: $bold;
				color: $main-color;
			}
			&--details {
				p {
					font-size: 0.875rem;
					margin-bottom: 0.25rem !important;
				}
			}
		}

		&__rating {
			&--header {
				h6 {
					color: #707070;
					font-size: 1rem;
					font-weight: 600;
					margin-top: 1rem;
				}
			}
		}

		&__money {
			p {
				font-size: 2.75rem;
				font-weight: bold;
				margin-inline-end: 0.5rem;
				color: $gray70-color;
			}
		}

		&__edit {
			p {
				font-size: 1rem;
				font-weight: 600;
				color: $gray70-color;
			}
		}
	}
	.empty_icon path {
		stroke: $gray70-color;
	}
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input {
		text-align: center;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}

	.edit-profile {
		&__figure {
			position: relative;
			&::before {
				position: absolute;
				content: "";
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba($main-color, 0.25);
				z-index: 999;
				cursor: pointer;
				transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
			}

			.camera-icon-upload {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 999;
				width: 2rem;
				height: 2rem;
			}
		}

		&__form {
			margin-top: 2rem;
		}

		&__btn {
			margin-top: 3.5rem;
			button {
				height: 42px;
			}
		}
	}
	.website {
		color: #12819c;
		font-weight: 700;
	}
	.overallRatingBox {
		.title {
			font-weight: bold;
		}
	}
}

@keyframes inAnimation {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

@keyframes outAnimation {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}
