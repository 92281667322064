
          @import "src/assets/scss/_shared.scss";
        
.ant-carousel {
	.ant-carousel .slick-dots li button {
		background-color: $main-color !important;
		height: 0.25rem;
	}
}
.features {
	&__item {
		position: relative;

		video {
			position: relative;
			z-index: -1;
		}
		@media (max-width: 768px) {
			.p-text {
				font-size: 12px;
			}
			.mainTitle {
				font-size: 14px;
			}
		}

		&--content {
			position: absolute;
			top: 10%;
			left: 0;
			width: 25%;
			margin-inline-start: 1rem;
			margin-inline-end: 1rem;
			text-align: start;
			z-index: 5;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			h4 {
				font-size: 2.5rem;
				font-weight: bold;
			}

			p {
				margin-bottom: 1rem !important;
				font-size: 1.15rem;
				font-weight: 600;
			}

			.primary-bid-btn {
				display: flex;
				justify-content: flex-start;
			}
		}
	}

	.carousel {
		.control-dots {
			margin-bottom: 1rem;
			.dot {
				box-shadow: none;
				width: 1rem;
				height: 1rem;
			}
		}
	}
}
@include to-tablet-sm {
	.features {
		&__item {
			video {
				position: relative;
				z-index: -1;
			}
			&::after {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background: rgba(white, 0.09);
				z-index: 1;
			}

			&--content {
				width: 80%;
				min-height: 78%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				z-index: 9;

				h4 {
					font-size: 1rem;
				}

				p {
					margin-bottom: 0.5rem !important;
					font-size: 0.85rem !important;
					flex: 1;
					.not-mobile {
						display: none;
					}
				}
				.primary-bid-btn {
					button {
						font-size: 0.85rem;
						height: 2rem;
					}
				}
			}
		}

		.carousel {
			.control-dots {
				margin-bottom: 0.5rem;
				.dot {
					width: 0.5rem;
					height: 0.5rem;
				}
			}
		}
	}
}

html.ar {
	.features__item--content {
		left: auto !important;
		right: 0;
		direction: rtl;
	}
}

@include tablet {
	.features {
		&__item {
			&--content {
				position: absolute;
				top: 10%;
				left: 0;
				width: 27%;
				margin-inline-start: 1rem;
				margin-inline-end: 1rem;
				text-align: start;
				z-index: 5;
				p {
					font-size: 1rem;
				}
			}
		}
	}
}
@include to-mobile-sm {
	.features {
		.slick-dots.slick-dots-bottom {
			bottom: 1% !important;
		}
		// .ant-carousel .slick-dots li button {
		// 	background-color: $main-color !important;
		// }
	}
}

@include mobile-sm {
	.features {
		&__item {
			&--content {
				margin-top: 1rem;
				width: 40%;
			}
		}
	}
}
