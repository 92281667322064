
          @import "src/assets/scss/_shared.scss";
        
.buy-modal {
  width: 650px !important;
  &__header {
    figure {
      margin-bottom: 0.5rem !important;
    }
    p {
      color: $main-color;
      font-size: 1.125rem;
      font-weight: $bold;
      margin-bottom: 0.75rem !important;
    }
  }
  &__packages {
    color: white;
    text-align: center;
    .package__coins {
      background: $main-color;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 1rem;
    }
    .coins {
      margin-bottom: 0.5rem;
      &-number {
        font-size: 2rem;
        font-weight: $bolder;
        margin-inline-end: 0.5rem;
      }
    }
    .money {
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
}

.buy-modal__payment-method {
  &-p {
    color: $main-color;
    font-size: 1rem;
    margin-top: 0.2rem;
    font-weight: bold;
  }
}
