
          @import "src/assets/scss/_shared.scss";
        
.forget {
	background: url("../../../../../assets/images/bg-3.svg") no-repeat left center;
	background-size: cover;
	min-height: 500px;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	&__header {
		h2 {
			font-size: 1.3rem;
			text-transform: uppercase;
			font-weight: bold;
			color: $main-color;
			margin-bottom: 1rem;
		}
	}

	&__modal {
		background-color: white;
		width: 420px;
		height: 340px;
		padding: 1rem 1rem;
		padding-inline: 2rem;
		.input-item {
			display: flex;
			flex-direction: column;
			flex: 1;
		}
	}
	&__btn-contianer {
		.ant-form-item-control-input {
			text-align: center;
		}
	}
	&__btn {
		margin: 2rem auto;
		text-align: center;
	}
}
