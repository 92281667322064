
          @import "src/assets/scss/_shared.scss";
        
.terms {
	&__section {
		max-width: 550px;
	}

	header {
		margin-bottom: 2rem;
	}

	&__content {
		overflow: auto;
		& > * {
			padding-inline-end: 0.5rem;
		}
		.item {
			h4 {
				font-size: 1rem;
				font-weight: bold;
				color: $black-color;
			}
			p {
				font-size: 0.85rem;
				color: $black-color;
			}
		}
		& > div {
			&:nth-of-type(1) {
				margin-right: unset !important;
				margin-inline-end: -17px;
			}
		}
	}

	.divider {
		border-bottom: 1px solid $gray70-color;
		margin-top: 0.25rem;
		margin-bottom: 2rem;
		margin-inline: 1rem;
		margin: 0.25rem 1.5rem 2rem 1.5rem;
	}

	&__scrollbar {
		background: $main-color;
		width: 3px !important;
		border-radius: 5px;
		padding: 0 !important;
	}
}

html.ar {
	.terms {
		&__content {
			& > div {
				&:nth-of-type(3) {
					right: auto !important;
					left: 2px;
				}
			}
		}
	}
}
