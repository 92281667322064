
          @import "src/assets/scss/_shared.scss";
        
.search-modal {
	.input {
		max-width: 70%;
		height: 42px;
		font-size: 1.5rem;
		color: #c1c1c1;
		margin-block: 1rem;
		.ant-input {
			font-size: 1.25rem;
			color: #c1c1c1;
			font-weight: 100;
		}
	}
	.result {
		margin-top: 1rem;
		padding-inline-end: 1rem;
		overflow-y: auto;
		height: 400px;
		position: relative;
		&__cat {
			border: 1px solid #c1c1c1;
			margin-bottom: 1rem;
			padding: 0.5rem;
			padding-bottom: 0;
			border-radius: 0.25rem;
			.category-title {
				color: $main-color;
				font-size: 1.25rem;
				font-weight: bold;
				border-bottom: 1px solid #c1c1c1;
				padding-bottom: 0.25rem;
			}
		}
		&__item {
			margin-bottom: 0.25rem;
		}
		&__title {
			font-weight: 400;
			color: #313131;
		}
		&__no-data {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			p {
				text-align: center;
				font-size: 1.225rem;
				color: #c1c1c1;
				margin-top: 0.5rem;
			}
		}
	}
}

@include to-tablet-sm {
	.search-modal {
		.ant-modal-body {
			width: 95%;
		}

		.input {
			max-width: 100%;
			padding: 0;
		}
	}
}
