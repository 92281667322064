
          @import "src/assets/scss/_shared.scss";
        
.shortlisting {
  .cadidates {
    font-weight: 600;
    font-size: 0.9rem;
  }

  .candidate-checked {
    font-weight: $bold !important;
    color: $main-color !important;
  }
  .form-check {
    display: flex !important;
    align-items: center;
    input {
      width: 20px;
      height: 20px;
      margin-inline-end: 5px;
      border-radius: 50%;
      margin-bottom: 0.3125rem;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .btn_submit {
    background: #12819c;
    color: #fff;
  }
  ul {
    padding-left: 0;
    li {
      h6 {
        color: $gray70-color;
      }
    }
  }
  .empty_icon path {
    stroke: $gray70-color;
  }
  .rating-list {
    svg {
      width: 26px;
      height: 26px;
      margin: 0;
      padding: 0;
    }
  }
  .submit {
    .number {
      color: $gray70-color;
      font-weight: bolder;
    }
    i {
      color: $main-color;
    }
    .desc {
      color: $gray89-color;
      opacity: 0.5;
      font-size: 1rem;
    }
  }
  .title {
    font-size: 1rem;
    font-weight: bold;
  }

  .edit,
  .remove {
    cursor: pointer;
  }
  .empty-portofolio-text {
    font-size: 1.25rem;
    color: $gray70-color;
  }
}

.rating-list {
  text-align: center;
  h6 {
    font-size: 0.8rem !important;
    font-weight: 700;
  }
}
