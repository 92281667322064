
          @import "src/assets/scss/_shared.scss";
        
.createProject {
	// createProject__draft-delete
	&__draft-delete {
		background: rgba(red, 0.07) !important;
		height: 42px !important;
		position: absolute !important;
		top: 0;
		right: 0;
		span {
			color: red;
			font-size: 0.75rem;
			border-bottom: 1px solid red;
		}
	}

	&__section {
		padding-inline: 1rem;
		position: relative;
	}

	&__name {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		label {
			width: 135px;
		}
		input {
			width: 250px;
		}
	}

	&__specialization {
		.ant-radio + span {
			font-size: 1.15rem;
			font-weight: normal;
		}
	}

	&-group {
		&__header {
			font-size: 1rem;
			color: $main-color;
			margin-bottom: 1.2rem;
		}
		.item {
			span {
				color: $gray70-color;
				font-size: 1rem;
				font-weight: $normal;
			}
		}
	}

	&__upload-drawings {
		button {
			width: 100%;
			height: 37px;
			border-radius: 0;
			&.ant-btn-primary {
				background: $main-color;
			}
			&:hover {
				background: $main-color;
				color: white;
			}
		}

		label {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			color: white;
			border-radius: 0;
			line-height: 2em;
			&:hover {
				color: white;
			}
		}
		.filename {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			color: $main-color;
			transition: 0.5s all ease-in-out;

			.text {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				&[data-title] {
					position: relative;
					cursor: help;
					&:hover &::before {
						content: attr(data-title);
						position: absolute;
						bottom: -46px;
						padding: 10px;
						background: #000;
						color: #fff;
						font-size: 14px;
						white-space: nowrap;
					}
				}
			}

			&__remove {
				color: $main-color;
			}
		}
	}

	&__paragraphs {
		margin-bottom: 2rem;
		.paragraph__container {
			margin-bottom: 1rem;
			label {
				color: $main-color;
				font-size: 1rem;
			}
			input {
				margin-bottom: 0.25rem;
			}
			small {
				color: #666;
			}
		}
	}

	&__type {
		.ant-radio + span {
			font-size: 1rem;
			font-weight: bold;
		}
	}

	&__select-box {
		width: 100%;
		margin-inline: 0.5rem;
		.ant-select-selector {
			height: 38px !important;
			border-color: $main-color !important;
			border-radius: 0 !important;
			align-items: center !important;
			color: $main-color !important;
		}
	}
	&__btn-group {
		display: flex;
		align-items: center;
		justify-content: center;
		& > button {
			margin-inline-end: 1rem;
			&.draft-btn {
				background: transparent;
				color: $main-color;
			}
			&:last-of-type {
				margin-inline-end: 0;
			}
		}
	}
}
.other-input {
	&.ant-input[disabled] {
		background: transparent;
		border-bottom: 1px solid #d4d4d4;
	}
	&.ant-input {
		border: 0;
		border-bottom: 1px solid gray;
		border-radius: 0 !important;
	}
}
.disclamer {
	p {
		color: #897f7f;
		font-size: 0.9375rem;
	}
}
.extra-comment {
	lable {
		font-weight: bold;
		color: $main-color;
	}
	input {
		margin-top: 1.5rem !important;
	}
}
.optional {
	box-shadow: $box-shadow;
	padding: 0.625rem;
	width: 99.5%;
	margin-inline: auto;
	.title {
		font: normal normal bold 16px/19px Montserrat;
		.title-desc {
			margin-inline-end: 0.3125rem;
		}
		img {
			max-width: 9.375rem;
		}
	}
	h6 {
		color: $gray70-color;
		font-weight: 500;
	}
}

.ant-radio-checked + span,
.ant-radio-checked + span .other-span {
	color: $main-color !important;
	font-weight: 600 !important;
	transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
}

.ant-input:focus,
.ant-input-focused {
	box-shadow: none !important;
	border-color: $main-color !important;
}
input.underline-input {
	border: 0;
	border-bottom: 1px solid gray;
	border-radius: 0 !important;
}

.space {
	&-item {
		margin-bottom: 1.5rem !important;
	}
}

@include to-tablet-sm {
	.createProject {
		&__name {
			flex-direction: column;
			align-items: flex-start;
			input {
				flex: 1;
			}
		}
	}
}

@include to-tablet-lg {
	.createProject {
		&__section {
			.project-inputs {
				.ant-form-item {
					flex-direction: column !important;
					.ant-form-item-label,
					.ant-form-item-control {
						flex: 1 !important;
					}
				}
			}
		}
		&__select-box {
			margin-inline: 0 !important;
		}
	}
}

html.ar {
	.createProject {
		// createProject__draft-delete
		&__draft-delete {
			left: 0;
			right: auto;
		}
	}
}
