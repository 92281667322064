
          @import "src/assets/scss/_shared.scss";
        
.projects__room {
  .new-project__service--type {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    li {
      .ant-checkbox-wrapper {
        color: $gray70-color;
      }
      .ant-checkbox-inner {
        width: 18px;
        height: 18px;
      }
    }
    .ant-checkbox-checked::after {
      border: 0 !important;
    }
  }
  .project__room-filters {
    > div {
      box-shadow: $box-shadow;
      width: 98%;
      margin-inline: auto;
      margin-bottom: 20px;
      padding: 15px 10px;
      position: relative;
      .delete {
        position: absolute;
        top: 5%;
        right: 1%;
        cursor: pointer;
      }
      .project-title {
        cursor: pointer;
        max-width: fit-content;
      }
    }
  }
  .project__details {
    .project__details--header {
      border-bottom: $border;
    }
    .title {
      color: $main-color;
    }
    .porperties {
      .project {
        h5 {
          color: $main-color;
        }
      }
      .measure {
        color: $main-color;
        font-weight: bold;
      }
      .budget h5,
      .time h5,
      .handover h5,
      .status,
      .invoice,
      .comments-title {
        color: $main-color;
        font: normal normal medium 16px/19px Montserrat;
      }
      .project-comments {
        p {
          color: #000;
        }
      }
      .button {
        background-color: $main-color;
        color: #fff;
        border: 2px solid $main-color;
        &:hover {
          background-color: #fff;
          color: $main-color;
        }
      }
      .new-project-btn {
        background-color: #fff;
        color: $main-color;
        border: 2px solid $main-color;
        &:hover {
          background-color: $main-color;
          color: #fff;
        }
      }
      .new-project-btn,
      .button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .download-icon {
          margin-inline-start: 10px;
        }
        &:focus {
          box-shadow: none !important;
        }
      }
    }
    .bid-button {
      background-color: $main-color;
      color: #fff;
      border: 2px solid $main-color;
      border-radius: 0;
      font-weight: 600;
      &:focus {
        box-shadow: none !important;
      }
    }
    p {
      font-size: 1rem;
    }
  }
  @include to-tablet-sm {
    .hide {
      display: none;
    }
  }
  @include from-desktop-sm {
    .new-project-btn,
    .button {
      width: 90%;
    }
  }
  .no-projects {
    h3 {
      color: $gray70-color;
      font-weight: 600;
    }
  }
}

html.ar {
  .projects__room .project__room-filters > div .delete {
    left: 1rem !important;
    right: auto !important;
  }
}
