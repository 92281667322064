
          @import "src/assets/scss/_shared.scss";
        
.feedback {
	min-height: 100vh;
	background: url("../../assets/images/bg-3.svg") no-repeat center center;
	background-size: cover;
	&__submit {
		display: flex;
		justify-content: center;
		button {
			width: 150px;
			height: 40px;
		}
	}

	&__logo {
		img {
			margin-block: 32px;
		}
	}

	&__container {
		background: white;
		padding: 1rem;
		margin-block-end: 2rem;
	}
	&__header {
		height: 60px;
		background: $main-color;
		display: flex;
		align-items: center;
		justify-content: center;
		h2 {
			font-size: 22px;
			color: white;
			text-align: center;
			margin-bottom: 0;
			font-weight: 600;
		}
	}

	h5 {
		font-size: 17px;
		font-weight: bold;
		color: #707070;
		margin-block: 24px;
	}

	&__questions {
		.question {
			margin-bottom: 2rem;

			&__header {
				font-size: 1rem;
				font-weight: bold;
				color: #707070;
				margin-bottom: 1.15rem;
				display: flex;
				align-items: center;
				span {
					width: 5px;
					height: 5px;
					border: 0.25rem solid $main-color;
					border-radius: 50%;
					padding: 0.1rem;
					display: block;
					margin-inline-end: 0.625rem;
				}
				&.quesiton-header-sm {
					font-weight: normal;
					font-size: 1rem;
				}
			}

			&__answers {
				.answer {
					&__wrapper {
						margin-bottom: 20px;
						&:last-of-type {
							margin-bottom: 0;
						}
						.ant-radio-wrapper {
							display: block;
							color: #707070;
							font-size: 1rem;
							span {
								// margin-inline-end: 0.7rem;
								font-size: 15px;
								font-weight: 400;
							}
						}
					}
				}
			}
			&__yes-no {
				margin-block: 1.25rem;
			}
			&__input {
				min-height: 230px;
				resize: none;
			}
			&__rating {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				@include to-tablet-sm {
					& {
						flex-direction: column;
					}
					& .start-text {
						display: flex;
						flex: 1;
						justify-content: flex-start;
						width: 100% !important;
					}
					& .end-text {
						display: flex;
						flex: 1;
						justify-content: flex-end;
						width: 100% !important;
					}
				}
				.text {
					// margin-inline-start: 2rem;
					// margin-inline-end: 2rem;
					font-size: 0.9375rem;
					font-weight: 600;
					width: 142px;
					color: #707070;
					&:last-of-type {
						margin-inline-end: 0;
					}
				}
				ul.rates {
					display: flex;
					align-items: center;
					position: relative;
					&::before {
						content: "";
						width: 100%;
						height: 8px;
						position: absolute;
						top: 50%;
						transform: translatey(-50%);
						left: 0;
						background-color: rgba($main-color, 0.5);
						z-index: 1;
					}
					.rate {
						width: 40px;
						height: 40px;
						border: 1px solid $main-color;
						background: white;
						color: $main-color;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						position: static;
						z-index: 2;
						margin-inline-end: 25px;
						cursor: pointer;
						@include to-mobile-sm {
							& {
								width: 30px;
								height: 30px;
							}
						}
						&:last-of-type {
							margin-inline-end: 0rem;
						}
						&:hover,
						&.active {
							background: $main-color;
							color: white;
							transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
						}
					}
				}
			}
		}
	}
}
