
          @import "src/assets/scss/_shared.scss";
        
.portofolio__project {
	.card-img-top {
		margin-bottom: 0.5rem;
		height: 200px;
	}
	.date {
		span {
			font-size: 0.85rem;
		}
	}

	.project__card {
		position: relative;
		.pending-files {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
			background-color: white;
			color: #fd0d1b;
			font-size: 1rem;
			font-weight: 600;
			padding: 0.25rem 0.5rem;
			border-radius: 0.25rem;
		}
		.title {
			font-size: 1.5rem;
			font-weight: bold;
			color: #3d3d3d;
		}
	}
	&.with-empty-string {
		display: flex;
		min-height: 150px;
		justify-content: center;
		align-items: center;
	}
}

html.ar {
	.project__card {
		.pending-files {
			right: auto !important;
			left: 0.5rem;
		}
	}
}
