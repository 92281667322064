
          @import "src/assets/scss/_shared.scss";
        
.auth {
	background: url("../../assets/images/bg-3.svg") no-repeat left center;
	background-size: cover;
	min-height: 500px;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	.ant-input-borderless {
		border-bottom: 1px solid $main-color !important;
		border-radius: 0;
	}
	.ant-input-affix-wrapper-borderless {
		border-bottom: 1px solid $main-color !important;
		border-radius: 0;
	}
	.ant-btn.ant-btn-submit.btn.auth-btn {
		&:hover,
		&:active,
		&:focus {
			background: $main-color;
			color: white;
		}
	}
	&-btn {
		&:hover,
		&:active {
			background: $main-color;
			color: white;
		}
	}
	&__section {
		background: white;
		min-width: 320px;
		max-width: 500px;
		margin: auto;
		padding: 1rem 2rem;
		box-shadow: 6px 6px 20px #0000001a;
		position: relative;
		.close-popup {
			position: absolute;
			right: 1rem;
			top: 1rem;
			cursor: pointer;
		}
	}

	&__form-content {
		.new-user {
			display: flex;
			font-size: 1rem;
			margin-bottom: 0.25rem;

			&__text {
				margin-inline-end: 0.25rem;
				font-weight: 600;
			}

			&__create {
				font-weight: 600;
				color: $main-color;
			}
		}

		.form {
			.ant-form-item {
				flex-direction: column;
				align-items: stretch;
			}
			.ant-input {
				&.ant-input-status-error:not(.ant-input-disabled):not(
						.ant-input-borderless
					).ant-input:focus {
					border: none;
					border-bottom: 1px solid #ff7875;
					box-shadow: none;
				}
			}
			.ant-input-affix-wrapper:focus,
			.ant-input-affix-wrapper-focused {
				border-color: $graycf-color;
				box-shadow: none;
			}

			&__item {
				.ant-form-item-label {
					text-align: left !important;
					label {
						color: $main-color;
						font-size: 0.875rem;
						font-weight: 600;
						&.ant-form-item-required:not(
								.ant-form-item-required-mark-optional
							)::after {
							content: "*";
							color: $main-color;
						}
						&.ant-form-item-required:not(
								.ant-form-item-required-mark-optional
							)::before {
							display: none;
						}
					}
				}
			}

			&__btn-submit {
				text-align: center;
				button {
					background: $main-color;
					color: white;
					width: 144px;
					height: 48px;
					border-radius: 0;
				}
			}

			&__forget {
				font-size: 0.85rem;
				font-weight: 600;
				color: $graycf-color;
				cursor: pointer;
				text-decoration: underline;
			}

			&__keep {
				display: flex;
				margin-top: 0.25rem;
				margin-bottom: 0.25rem;
				&.alert {
					.ant-checkbox-inner {
						border-color: red;
						animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
						transform: translate3d(0, 0, 0);
						backface-visibility: hidden;
						perspective: 1000px;
					}
				}
				.ant-checkbox-checked {
					.ant-checkbox-inner {
						border-color: $main-color !important;
					}
				}

				label {
					margin-inline-start: 0.5rem;
					font-size: 0.875rem;
					font-weight: bold;
					color: $black-color;
					cursor: pointer;
				}
			}
		}
	}

	.signin-with-other {
		text-align: center;
		justify-content: space-around;

		&__title {
			display: flex;
			justify-content: center;
			align-items: center;
			h3 {
				font-size: 0.875rem;
				font-weight: bold;
				margin-bottom: 0;
			}
			span {
				display: block;
				width: 80px;
				height: 1px;
				background: $graycf-color;
				margin: 0 0.5rem;
			}
		}

		&__content {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 0.5rem;
			.item {
				margin-inline-end: 0.25rem;
				cursor: pointer;
				&:last-of-type {
					margin-inline-end: 0;
				}
			}
		}
	}

	&__section {
		&.signup__section {
			max-width: 720px;
			.form__keep {
				justify-content: center;

				p {
					font-weight: bold;
				}
				label {
					font-weight: 400;
				}
			}
		}
	}
	&__user-type {
		font-size: 1rem;
		font-weight: 600;
		margin-block: 1rem;

		.ant-radio-inner::after {
			background: $main-color;
		}
		.ant-radio-wrapper:hover .ant-radio,
		.ant-radio:hover .ant-radio-inner,
		.ant-radio-input:focus + .ant-radio-inner {
			border-color: $main-color !important;
		}
	}
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
		appearance: textfield;
	}
}

@include to-mobile-sm {
	.auth {
		&__section {
			height: 100%;
			width: 100%;
			min-width: 320px;
			&.signup__section {
				min-height: 730px;
				.form__keep {
					flex-direction: column;
				}
			}
		}
	}
	.auth__form-content .form .ant-form-item {
		flex-direction: row;
	}
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}

.auth__btn__anchor{
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  display: contents !important;
  color: #12819c !important;
  font-weight: 600 !important;
}