
          @import "src/assets/scss/_shared.scss";
        
.subnav {
	padding-block: 1.25rem;
	position: relative;

	&__list {
		&.pro {
			display: grid;
			grid-template-columns: auto auto auto auto auto;
			grid-column-gap: 1.5625rem;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}

		&.client {
			display: flex;
		}
	}

	&__item {
		font-size: 1rem;
		line-height: 1.23;
		font-weight: bold;
		a {
			color: #313131;
		}
		&.client {
			margin-inline-end: 1.5625rem;
		}
	}
	.active {
		color: $main-color;
	}
}

@include to-tablet-sm {
	.subnav {
		overflow-x: auto;
		padding-inline: 0.25rem;
		&__pro {
			.subnav__list {
				min-width: 785px;
			}
		}

		&__client {
			.subnav__list {
				min-width: 378px;
			}
		}
	}
}
