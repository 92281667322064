
          @import "src/assets/scss/_shared.scss";
        
.empty_icon {
	path {
		fill: transparent;
		stroke: $main-color !important;
	}
}
// .ant-rate-star {
// 	svg {
// 		fill: $main-color;
// 	}
// }
