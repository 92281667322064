
          @import "src/assets/scss/_shared.scss";
        
.home-services {
  background: #f7f7f7;
  padding-block: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .home-services__intro {
    .home-services__title {
      color: $main-color;
      font-weight: bold;
    }
  }
  .home-services__service {
    &-card {
      border-radius: 0.5rem;
    }
    &-img-icon-container {
      position: relative;
    }
    &-img {
      border-radius: 0.5rem;
      position: static;
      z-index: 1;
    }
    &-icon {
      width: 5rem;
      height: 5rem;
      position: absolute;
      bottom: -4.5rem;
      z-index: 2;
      left: 50%;
      transform: translate(-50%, -50%) !important;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 2.5rem;
        margin: 1rem;
      }
    }

    &-title.h5 {
      margin-top: 1.5rem;
      font-size: 1rem !important;
      font-weight: bold;
      color: black !important;
    }
  }
  .react-multi-carousel-track {
    margin-bottom: 2.2rem;
  }
  .react-multi-carousel-dot button {
    border: 0 !important;
  }
  .react-multi-carousel-dot--active button {
    background: $main-color !important;
  }
}
