
          @import "src/assets/scss/_shared.scss";
        
.viewServiceType {
  &__section {
    margin-bottom: 2rem;
    position: relative;
    .disclaimer {
      color: #897f7f;
      font-size: 0.85rem;
      text-align: center;
    }
    .edit-btn {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  &__specialization,
  &__type {
    margin-bottom: 1rem;
  }
  &__specialization {
    font-size: 1rem;
    font-weight: $bold;
    color: $main-color;
  }
  &__type {
    font-weight: $bold;
    &-item {
      color: #1c2126;
      font-size: 1rem;
    }
  }
  &__header {
    h4 {
      font-size: 1.5rem;
      color: $main-color;
    }
  }

  &__item {
    margin-bottom: 1.5rem;
    p {
      font-size: 0.9rem;
      color: $black-color;
    }
  }
  &__footer {
    p {
      font-size: 1rem;
      color: #897f7f;
    }
  }
}

html.ar {
  .viewServiceType__section {
    .edit-btn {
      unicode-bidi: bidi-override !important;
      direction: unset !important;
      position: absolute;
      right: auto;
      left: 1rem;
    }
  }
}
