
          @import "src/assets/scss/_shared.scss";
        
.navbar__container {
	box-shadow: 0px 3px 6px #00000073;
	z-index: 99;

	&.sticky {
		position: sticky;
		top: 0;
	}

	.navbar {
		background: white !important;
	}

	.nav-container {
		img {
			max-width: 100%;
		}
		.links-container {
			> div {
				.form {
					flex: 1;
					margin-inline-end: 1rem;
					form {
						width: 100%;
					}
					.search-group {
						border-bottom: 1px solid $graycf-color;
						background: transparent;
						.search-input {
							border: 0;
							border-bottom-left-radius: 0;
							border-bottom-right-radius: 0;
							background: transparent;
							position: relative;
							&:focus {
								box-shadow: none;
							}
						}
						#basic-search {
							background: none;
							border: 0;
						}
					}
				}
				.form-signinup {
					.signin-up__link {
						color: #12819c;
						font-weight: bold;
						padding-right: 0;
					}
				}
				.links {
					flex-basis: 60%;
					.links-link {
						color: $black-color !important;
						font-weight: 600;
					}
					.nav-link-active {
						color: $main-color;
						font-weight: bold;
					}
				}
				.signin-up {
					.profile-img {
						cursor: pointer;
						border: 0.125rem solid $main-color;
						border-radius: 50%;
						figure {
							position: relative;
							width: 40px;
							height: 40px;
							border-radius: 50%;

							.user-avatar {
								width: 100%;
								height: 100%;
								border-radius: 50%;
								overflow: hidden;
							}
						}
					}
				}
			}
		}
		.mobile-signinup {
			color: #12819c;
			font-weight: bold;
		}
		.mobile-links {
			.profile-img {
				cursor: pointer;
				border: 0.125rem solid $main-color;
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 50%;
				figure {
					position: relative;
					.user-avatar {
						object-fit: contain;
						border-radius: 50%;
					}
					span {
						position: absolute;
						right: 0;
						bottom: -0.625rem;
						color: red;
						font-weight: bold;
						font-size: 1.125rem;
					}
				}
			}
		}
	}
	.mobile-links {
		display: none;

		.navbar-toggler:focus {
			box-shadow: none !important;
		}
	}
}
.ant-modal-body h3 {
	color: $main-color;
	font-weight: 600;
	margin-bottom: 50px;
}
.ant-form-item-label {
	text-align: left !important;
	label {
		color: $main-color !important;
		font-size: 0.875rem;
		font-weight: 600;
		&.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
			content: "*";
			color: #ff7875;
		}

		&.ant-form-item-required:not(
				.ant-form-item-required-mark-optional
			)::before {
			display: none !important;
		}
	}
}
.settings-form {
	.ant-form-item {
		flex-direction: column;
		align-items: stretch;
	}
	.ant-input {
		&.ant-input-status-error:not(.ant-input-disabled):not(
				.ant-input-borderless
			).ant-input:focus {
			border: none;
			border-bottom: 1px solid #ff7875;
			box-shadow: none;
		}
	}
	.ant-input-affix-wrapper:focus,
	.ant-input-affix-wrapper-focused {
		border-color: $graycf-color;
		box-shadow: none;
	}

	&__btn-submit {
		text-align: center;
		button {
			background: $main-color;
			color: white;
			width: 144px;
			height: 48px;
			border-radius: 0;
		}
	}

	&__forget {
		font-size: 0.85rem;
		font-weight: 600;
		color: $graycf-color;
		cursor: pointer;
		text-decoration: underline;
	}

	&__keep {
		display: flex;
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;

		.ant-checkbox-checked .ant-checkbox-inner {
			border-color: $main-color;
			background-color: $main-color;
			border-radius: 0;
		}
		.ant-checkbox-checked:hover .ant-checkbox-inner {
			border-color: $main-color;
		}
		.ant-checkbox-wrapper:hover .ant-checkbox-inner,
		.ant-checkbox:hover .ant-checkbox-inner {
			border-color: $main-color;
		}

		.ant-checkbox-inner:hover,
		.ant-checkbox-input:focus + .ant-checkbox-inner {
			border-color: $main-color;
		}

		label {
			margin-inline-start: 0.5rem;
			font-size: 0.875rem;
			font-weight: bold;
			color: $black-color;
			cursor: pointer;
		}
	}
	.change-password {
		text-decoration: underline;
		cursor: pointer;
		text-align: end;
	}
}

.profile-dropdown {
	&.ant-dropdown-menu {
		margin-inline: 1rem;
	}
	.ant-dropdown-menu-title-content {
		color: gray;
	}
}
.localizationDropdown {
	.ant-dropdown-menu-item {
		color: #bdbdbd !important;
		text-align: center !important;
		&.active {
			color: #5fa9bb !important;
		}
	}
}

@media (max-width: 991px) {
	.nav-container {
		justify-content: space-between !important;
		.nav-logo {
			flex-basis: 40% !important;
		}
		.form {
			flex-basis: 100% !important;
		}
		.mobile-links {
			display: flex !important;
			align-items: center;
		}
		.links-container {
			> div {
				position: absolute;
				right: 0;
				top: 36px;
				width: 30vw !important;
				background-color: #fff;
				padding-inline-start: 0.9375rem;
				padding-inline-end: 0.9375rem;
				padding-top: 0.9375rem;
				padding-bottom: 0.9375rem;
				box-shadow: 0rem 0.1875rem 0.375rem #00000029;
				z-index: 10;
				.links {
					li:not(:last-of-type) {
						border-bottom: 0.0625rem solid $graycf-color;
					}
				}
			}
			.signin-up {
				display: none !important;
			}
		}
		.mobile-signinup {
			display: block !important;
		}
	}

	html.ar {
		.nav-container .links-container > div {
			right: auto !important;
			left: 1rem;
		}
	}
}

@include to-tablet-sm {
	.nav-container {
		.links-container {
			> div {
				width: 50vw !important;
			}
		}
		.mobile-links {
			flex: 1;
			justify-content: flex-end;
		}
	}

	.navbar__container .nav-container .mobile-signinup {
		padding-inline: 0.5rem;
	}

	.form-signinup {
		width: auto;
		flex-direction: row;
		justify-content: space-between !important;
		.form {
			flex-basis: 90% !important;
			margin-inline-end: 0 !important;
		}
	}
}
.navbar-counter {
	position: absolute;
	right: -20%;
	bottom: -20%;
	color: red;
	font-weight: bold;
	font-size: 1.125rem;
}

html.ar {
	.navbar-counter {
		right: auto;
		left: -7%;
	}
}

@include to-mobile-sm {
	.navbar-counter {
		right: -20%;
		bottom: -20%;
	}

	.navbar__container .nav-container .links-container > div .links .links-link {
		font-size: 1rem;
		font-weight: 400;
	}
	html.ar {
		.navbar-counter {
			right: auto;
			left: -7%;
		}
	}
}

@include from-tablet-lg {
	.nav-container {
		.links-container {
			flex-grow: 0;
			> div {
				flex-direction: column !important;
				align-items: center;
			}
			.form-signinup {
				justify-content: space-between;
				width: 100%;
				.form {
					flex: 1;
					margin-inline-end: 0 !important;
				}
			}
		}
	}
}

@include from-desktop-sm {
	.nav-container {
		.nav-logo {
			flex-basis: 20%;
		}
		.links-container {
			flex-basis: 80%;
			> div {
				flex-direction: row-reverse !important;
				flex: 1;
			}
			.links {
				flex-basis: 65% !important;
			}
			.form-signinup {
				justify-content: space-between;
				flex-basis: 35%;
			}
		}
	}
}

.profile__drop-down {
	position: absolute;
	background-color: #fff;
	width: 7rem;
	top: 115%;
	left: -150%;
	z-index: 10;
}

.navbar-toggler {
	div {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		.bar {
			display: block;
			width: 100%;
			border-radius: 0.25rem;
			height: 3px;
			background: $main-color;
		}
	}
}
.navbar-light .navbar-toggler {
	border: none;
	height: 2rem;
	width: 2rem;
	justify-content: center;
	align-items: center;
	display: flex;
	padding: 0;
}

.divider {
	background-color: $main-color;
}
