
          @import "src/assets/scss/_shared.scss";
        
@import "~bootstrap/scss/bootstrap";
body,
html {
  font-family: monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.cursor-pointer {
  cursor: pointer;
}
li,
ul {
  padding: 0;
  margin: 0;
}
a,
a:hover {
  color: inherit;
  text-decoration: none;
}

html {
  font-size: 16px;
}

figure {
  margin-bottom: 0 !important;
}

p {
  margin-bottom: 0 !important;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  main {
    flex: 1;
  }
}

// Global Radio Boxs
.ant-radio-inner::after {
  background: $main-color;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $main-color !important;
}

@include to-desktop-sm {
  html {
    font-size: 14px;
  }
}

html.ar {
  .close-popup {
    right: auto;
    left: 1rem;
  }
}
