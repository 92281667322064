
          @import "src/assets/scss/_shared.scss";
        
.submitted {
	h2 {
		font: normal normal bold 1.0625rem/1.25rem Montserrat;
		font-size: 1.57rem !important;
	}
	h2.title {
		font: normal normal bold 1.5625rem/1.875rem Montserrat;
		color: $main-color !important;
	}
	h5 {
		font: normal normal medium 16px/1.1875rem Montserrat;
	}
	.btn {
		font-size: 14px;
	}
	.title {
		color: #12819c !important;
	}
	.porperties {
		.project {
			h5 {
				color: $main-color;
			}
		}
		.measure {
			color: $main-color;
			font-weight: bold;
		}
		.budget h5,
		.time h5,
		.handover h5,
		.status,
		.invoice,
		.comments-title {
			color: $main-color;
			font: normal normal medium 16px/19px Montserrat;
		}
		.project-comments {
			p {
				color: #000;
			}
		}
		.button {
			background-color: $main-color;
			color: #fff;
			.download-icon {
				margin-inline-start: 10px;
			}
			&:focus {
				box-shadow: none !important;
			}
		}
	}
	.optional {
		box-shadow: $box-shadow;
		padding: 0.625rem;
		width: 99.5%;
		margin-left: auto;
		margin-right: auto;
		.title {
			font: normal normal bold 16px/19px Montserrat;
			.title-desc {
				margin-inline-end: 0.3125rem;
			}
			img {
				max-width: 9.375rem;
			}
		}
		h6 {
			color: $gray70-color;
			font-weight: 500;
		}
	}
	p,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 1rem !important;
	}
}

@include to-tablet-sm {
	.porperties {
		.button {
			width: 100%;
		}
	}

	.hide {
		display: none;
	}
}
