
          @import "src/assets/scss/_shared.scss";
        
.home {
  &__intouch {
    .intouch {
      &__pgh {
        font-size: 1rem;
        color: $gray70-color;
        font-weight: 100;
        margin-bottom: 1rem !important;
      }
      &__form {
        &--input {
          border-color: $black-color;
          border-radius: 0;
          font-size: 1rem;
          padding: 0.715rem;
          &::placeholder {
            color: $main-color;
          }
          &:focus {
            box-shadow: none !important;
          }
        }
        button {
          &:focus {
            box-shadow: none !important;
          }
        }
      }
    }
  }
}
