
          @import "src/assets/scss/_shared.scss";
        
.guest-popup {
	.ant-modal {
		width: 50vw;
	}
}
.touse {
	&__content {
		& > div {
			&:nth-of-type(1) {
				margin-right: unset !important;
				padding-inline: 0.5rem;
				margin-inline-end: -17px;
				& > div:first-of-type {
					width: 100%;
				}
			}
			&::-webkit-scrollbar {
				display: none; /* Safari and Chrome */
			}
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			scrollbar-width: none; /* Firefox */
		}
		& > div:first-of-type {
			width: 100%;
		}
		&::-webkit-scrollbar {
			display: none; /* Safari and Chrome */
		}
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		scrollbar-width: none; /* Firefox */
	}
	&__header {
		h3 {
			text-align: center;
			margin-block: 1rem 2rem;
		}
	}

	&__title {
		font-size: 1.25rem;
		margin-bottom: 0.5rem;
		font-weight: bold;
	}
	&__item {
		margin-bottom: 1.5rem;
	}

	&__scrollbar {
		background: #b2b2b2;
		width: 3px !important;
		border-radius: 5px;
		padding: 0 !important;
	}
}

@include to-mobile-sm {
	.guest-popup {
		.ant-modal {
			width: 95vw;
		}
	}
}

html.ar {
	.touse {
		&__content {
			& > div {
				&:nth-of-type(3) {
					right: auto !important;
					left: 2px;
				}
			}
		}
	}
}
