
          @import "src/assets/scss/_shared.scss";
        
.modal-comp {
  .anticon.anticon-close.ant-modal-close-icon {
    color: $main-color;
  }
  &.ant-modal {
    .modal-title {
      color: $main-color;
      font-weight: 600;
    }
    .desc,
    .confirmation {
      font-weight: 600 !important;
    }
    .desc {
      font-size: 1rem !important;
    }
    .subDesc {
      color: #897f7f !important;
      font-size: 1rem;
    }
    .confirmation {
      font-size: 0.9rem !important;
      color: $gray89-color !important;
    }
    .buttons {
      width: fit-content;
      button {
        padding-inline: 2rem !important;
      }
      .cancel {
        background: none;
        color: $main-color;
        margin-inline-end: 2rem;

        &:hover {
          background-color: $main-color;
          color: #fff;
        }
      }
      .confirm {
        background-color: $main-color;
        color: #fff;
        &:hover {
          background: none;
          color: $main-color;
        }
      }
      .cancel,
      .confirm {
        border: 2px solid $main-color;
        border-radius: 0;
        font-weight: 600;
        height: auto;
        transition: 0.2s;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

@include to-mobile-sm {
  .buttons {
    width: 100%;
    align-items: center !important;
    flex-direction: row !important;
    justify-content: space-around !important;
    button {
      padding-inline: 2rem !important;
    }
    .cancel {
      background: none;
      color: $main-color;
      margin-inline-end: 3rem;

      &:hover {
        background-color: $main-color;
        color: #fff;
      }
    }
    .confirm {
      background-color: $main-color;
      color: #fff;
      &:hover {
        background: none;
        color: $main-color;
      }
    }
    .cancel,
    .confirm {
      border: 2px solid $main-color;
      border-radius: 0;
      font-weight: 600;
      height: auto;
      transition: 0.2s;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
