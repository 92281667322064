
          @import "src/assets/scss/_shared.scss";
        
.ratepro {
	&__client-header {
		margin-bottom: 1rem;
		p {
			font-size: 1rem;
			color: $main-color;
			margin-bottom: 0.25rem !important;
		}
	}
	&__header {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		p {
			font-size: 1.1rem;
			margin-bottom: 1rem !important;
		}
	}
	&__projects {
		.item {
			margin-bottom: 1.5rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			margin-right: 5px;
			margin-left: 5px;
			padding: 0.75rem 0.25rem;
		}
	}
	&__img {
		width: 6.25rem;
		height: 6.25rem;
		border-radius: 50%;
		border: 1px solid gray;
		overflow: hidden;
		align-self: center;
		justify-self: center;
		img {
			width: 100%;
		}
	}
	&__project {
		color: $main-color;
		font-size: 1rem;
		font-weight: $bold;
	}

	&__rating-wrapper {
		text-align: center;
		h6 {
			font-size: 0.6rem;
			color: $gray70-color;
			font-weight: bold;
			margin-bottom: 0.25rem;
		}
	}
	&__comment {
		label {
			font-size: 1rem;
			color: $main-color;
			font-weight: normal;
		}
	}
	&__selectbox {
		margin-bottom: 0.5rem !important;
	}
}

@include to-tablet-lg {
	.ratepro {
		&__comment {
			margin-bottom: 1rem;
		}
		&__projects {
			.item {
				padding: 1rem;
			}
		}
	}
}
