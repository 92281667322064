@media to-mobile-sm {
	html {
		font-size: 12px;
	}
}
body {
	overflow-x: hidden;
	overflow-y: auto;
}
* {
	overflow-wrap: break-word;
}

.box-shadow {
	box-shadow: $box-shadow;
}
.main-color {
	color: $main-color;
}
.main-bg {
	color: $main-color;
}
// form underline
.ant-input-affix-wrapper-status-error:not(
		.ant-input-affix-wrapper-disabled
	):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus {
	box-shadow: none !important;
}

.form-underline {
	border: none;
	border-bottom: 1px solid $graycf-color;
	border-radius: 0;
	background-color: transparent;

	&.ant-input:focus,
	&.ant-input:hover {
		box-shadow: none;
		border-color: $graycf-color;
		outline: 0;
	}

	&.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
	.ant-input-affix-wrapper:focus,
	.ant-input-affix-wrapper-focused {
		box-shadow: 0;
		border-color: $graycf-color;
		outline: 0;
	}
}
.ant-checkbox-checked .ant-checkbox-inner {
	background-color: $main-color;
	border-color: $main-color;
}

// text global styling
.text-bold {
	font-weight: $bold;
}
.text-bolder {
	font-weight: $bolder;
}

.primary-bid-btn {
	text-align: center;
	button {
		min-width: 144px;
		height: 48px;
	}
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
	color: $main-color;
}

.auth {
	&__logo {
		margin-bottom: 1rem !important;
	}
	&__title {
		font-size: 1.3rem;
		// text-transform: uppercase;
		font-weight: bold;
		color: $main-color;
	}
}

/* classes - colors */
.main-bg {
	background: $main-color;
}
.main-color {
	color: $main-color;
}

button.btn-main,
button.btn-main:hover {
	background: $main-color;
	color: white;
	min-width: 144px;
	border-radius: 0;
	&:hover {
		color: white !important;
	}
}

// inline-classes
.flex-1 {
	flex: 1;
}

ul li {
	list-style: none;
}

.container {
	width: 95% !important;
	max-width: 95% !important;
}
@media to-tablet-lg {
	.container {
		width: 95% !important;
		max-width: 95% !important;
	}
}

.main-section {
	box-shadow: $box-shadow;
	background: white;
	padding: 1.5rem 1rem;
	flex: 1;
	min-height: 450px;
}

//Font Sizes
.f-10 {
	font-size: 10px;
}
.f-12 {
	font-size: 12px;
}
.f-13 {
	font-size: 13px;
}
.f-18 {
	font-size: 18px;
}
.f-24 {
	font-size: 24px;
}
//Font Weight
.fw-500 {
	font-weight: 500;
}
.fw-600 {
	font-weight: 600;
}
.fw-700 {
	font-weight: 700;
}

// ant components customization

.ant-input-borderless,
.ant-input-affix-wrapper-borderless {
	border-bottom: 1px solid $main-color !important;
	border-radius: 0 !important;
}
// spinner
.with__spinner {
	position: relative;
	.spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
		z-index: 99;
		&__container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($main-color, 0.09);
			z-index: 999;
		}
	}
}

// masonry
.my-masonry-grid {
	display: -webkit-box;
	display: -ms-flexbox;
	column-gap: 0.5rem;
	display: flex;
	width: auto;
}
.my-masonry-grid_column {
	background-clip: padding-box;
}
.my-masonry-grid_column:nth-of-type(2n) {
	padding-inline-start: 0.75rem;
}
.my-masonry-grid_column:nth-of-type(n) {
	padding-inline-end: 0.75rem;
}

.my-masonry-grid_column > div {
	margin-bottom: 1rem;
}

@include to-tablet-sm {
	.my-masonry-grid_column:nth-of-type(1n),
	.my-masonry-grid_column:nth-of-type(2n) {
		padding-inline-start: 0 !important;
		padding-inline-end: 0 !important;
	}
}
.text-empty {
	color: #707070;
	font-size: 1.1rem;
	font-weight: 400;
}
