
          @import "src/assets/scss/_shared.scss";
        
.chart__bars {
	flex-direction: column;
	display: flex;
	justify-content: space-around;
	height: 100% !important;

	&-header {
		h4 {
			color: $main-color;
			font-size: 16px;
			text-align: center;
			margin-block: 16px;
		}
	}
	.bars {
		display: flex;
		justify-content: space-between;
		.bar-details {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			.bar {
				background: #e3e3e3;
				display: block;
				height: 10rem;
				width: 19px;
				border-radius: 0.5rem;
				position: relative;
				overflow: hidden;
			}
			span {
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				border-radius: 0.5rem;
				transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
			}

			&:nth-of-type(1) span {
				background: #12819c;
			}
			&:nth-of-type(2) span {
				background: #2693ad;
			}
			&:nth-of-type(3) span {
				background: #609faf;
			}
			&:nth-of-type(4) span {
				background: #609faf;
			}
			.title {
				font-size: 0.875rem;
				font-weight: bold;
				margin-top: 0.5rem;
				color: $main-color;
			}
		}
	}
}
.shortlistedOverBids {
	display: flex;
	flex-direction: column;
	position: relative;

	&__header {
		h6 {
			font-weight: bold;
			font-size: 1rem;
			color: #12819c;
			text-align: center;
			margin-bottom: 1.5rem;
		}
	}

	&__label {
		display: flex;
		justify-content: center;
		margin-top: 1rem;
		align-items: center;
		span {
			width: 0.75rem;
			height: 0.75rem;
			border-radius: 50%;
			background-color: $main-color;
			margin-inline-end: 1rem;
		}
	}

	&__text {
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%);
		color: $main-color;
		font-size: 5rem;
		font-weight: bold;
	}
}

@include to-tablet-sm {
	.shortlistedOverBids {
		&__text {
			font-size: 7rem;
		}
	}
}

@include to-mobile-sm {
	.shortlistedOverBids {
		&__text {
			font-size: 3rem;
		}
	}
}
