
          @import "src/assets/scss/_shared.scss";
        
.blog-page {
  .blog {
    &__hero {
      height: 100vh;
      min-height: 400px;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    &__header {
      h2 {
        font-size: 3rem;
        font-weight: $bold;
        color: white;
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.25rem;
        color: white;
        font-weight: $normal;
        text-align: center;
      }
    }
    &__tabs {
      margin-top: 2.5rem;

      .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
        justify-content: center;
      }

      .ant-tabs-tab {
        font-weight: $bold;
        color: $gray70-color;
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $main-color;
      }

      .ant-tabs-tab-btn:focus,
      .ant-tabs-tab-remove:focus,
      .ant-tabs-tab-btn:active,
      .ant-tabs-tab-remove:active,
      .ant-tabs-tab:hover {
        color: $main-color;
      }

      .ant-tabs-ink-bar {
        background: $main-color;
      }
    }
  }
}
