
          @import "src/assets/scss/_shared.scss";
        
$gap-size: 1rem;
.category-blog {
	.blog {
		&-item {
			margin-bottom: $gap-size;
			box-shadow: $box-shadow;
			padding: 1rem;
			&__fig {
				margin-bottom: 0.75rem !important;
				img {
					width: 100%;
				}
			}
			&__sub-header {
				font-size: 0.9rem;
				color: $main-color;
				font-weight: $bold;
				margin-bottom: 0.75rem;
			}
			&__header {
				h4 {
					color: $gray3d-color;
					font-size: 1.5rem;
					font-weight: $bold;
					margin-bottom: 0.75rem;
				}
			}
			&__desc {
				font-size: 1rem;
				line-height: 1.5;
				color: $gray70-color;
				max-height: 67px;
				overflow: hidden;
				cursor: pointer;
			}
		}
	}
	.my-masonry-grid_column:nth-of-type(n) {
		padding-inline: 0 !important;
	}
	.my-masonry-grid {
		gap: $gap-size;
	}
}
