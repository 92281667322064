
          @import "src/assets/scss/_shared.scss";
        
.home {
	&__FAQ {
		background: $main-color;

		header {
			h2 {
				color: white;
				font-weight: bold;
			}
		}

		.accordion-button::after {
			margin-left: unset;
			margin-inline-start: auto !important;
		}
		.accordion-item {
			background: none;
			border: none;
		}
		.accordion-body {
			margin: 0.5rem 0;
			background: transparent;
			font-size: 1rem;
			color: $gray70-color;
		}
		.accordion-collapse {
			background: white;
			color: $gray70-color;
		}

		.accordion-item {
			background: none !important;
			margin: 0.5rem 0;
			border-radius: 0 !important;
			&:first-of-type .accordion-button,
			&:last-of-type .accordion-button,
			&:last-of-type .accordion-collapse {
				border-radius: 0 !important;
			}
		}
		.accordion-button:not(.collapsed) {
			color: $main-color;
			background: white;
		}
		.accordion-button {
			color: $main-color;
			font-size: 1rem;
			font-weight: 600;
		}

		.accordion-button::after {
			background-image: url("../../../../assets/icons/chevron-up.svg");
			width: 2rem;
			height: 2rem;
			background-size: 2rem;
		}
		.accordion-button:not(.collapsed)::after {
			background-image: url("../../../../assets/icons/chevron-up.svg");
		}
	}
}
