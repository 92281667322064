
          @import "src/assets/scss/_shared.scss";
        
.viewServiceType__type-item {
	color: $black-color;
}

.projects__room {
	.project__details-details--header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.coins-tokens {
			align-items: baseline;
			.coins-tokens__text {
				font-size: 2.75rem;
				font-weight: 900;
				color: gray;
				margin-inline-end: 0.5rem;
			}
		}
	}
	.button {
		background-color: $main-color;
		color: #fff;
		.download-icon {
			margin-inline-start: 10px;
		}
		&:focus {
			box-shadow: none !important;
		}
	}
	.project__details {
		.project__details--header {
			border-bottom: $border;
			.project__details-name {
				display: flex;
				align-items: baseline;
				font-size: 1.25rem;
				color: $black-color;
				small {
					font-size: 0.75rem;
					font-weight: bold;
				}
			}
		}
		.serviceType {
			font-size: 1rem;
			font-weight: bold;
			color: $black-color;
		}
		.title {
			font-size: 1.5rem;
			font-weight: bold;
			color: $main-color;
		}
		.porperties {
			.project {
				h5 {
					color: $main-color;
				}
			}
			.measure {
				color: $main-color;
				font-weight: bold;
				margin-inline-start: 0.5rem;
			}

			.question {
				.question {
					color: $main-color;
					font-size: 1rem;
				}
				.answer {
					color: $black-color;
					font-size: 0.9rem;
				}
			}
			.project-comments {
				padding-bottom: 1rem;
				// &.comment-border {
				// 	border-bottom: 2px solid $main-color;
				// }
				p {
					font-size: 1.25rem;
					font-weight: bold;
					color: $black-color;
				}
			}

			.new-project-btn {
				background-color: #fff;
				color: $main-color;
				border: 2px solid $main-color;
				&.offer-btn {
					width: 175px;
					margin-bottom: 1.5rem !important;
				}
				&:hover {
					background-color: $main-color;
					color: #fff;
				}
			}
			.new-project-btn,
			.button {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-inline: 0.5rem;
				font-size: 0.86rem;
				.download-icon {
					margin-inline-start: 10px;
				}
				&:focus {
					box-shadow: none !important;
				}
			}
		}
		.bid-button {
			background-color: $main-color;
			color: #fff;
			border: 2px solid $main-color;
			border-radius: 0;
			font-weight: 600;
			&:focus {
				box-shadow: none !important;
			}
		}
		.message {
			font-size: 0.75rem;
			color: $gray70-color;
			padding-top: 1rem;
			border-top: 2px solid $main-color;
		}
	}
	.upload-area-container {
		display: flex;
		width: 100%;
	}
	.ant-upload.ant-upload-select {
		flex: 1;
		width: 100%;
		border: 1.5px dashed $main-color;
		border-radius: 0.5rem;
	}

	.upload-area {
		padding: 1.5rem 1rem;
		min-height: 100px;
		align-items: center;
		justify-content: center;
		display: flex;
		flex-direction: column;
		width: 100%;
		img {
			margin-bottom: 1.5rem;
		}
		h2 {
			font-size: 1.25rem;
			font-weight: bold;
			color: $gray70-color;
		}
	}
}

.projectRoom__messages-card {
	margin-inline: 1rem;
	padding: 1rem 0.75rem;
	box-shadow: $box-shadow;
	text-align: center;
	margin-bottom: 1rem;
	header {
		h4 {
			color: $main-color;
			font-size: 1.12rem;
			font-weight: bold;
		}
	}
	p {
		color: $main-color;
	}

	.projectRoom__messages-radio {
		span {
			font-size: 1rem;
			font-weight: normal;
			color: $gray70-color;
		}
	}
	span.or {
		font-size: 1.25rem;
		font-weight: bold;
		color: $main-color;
	}
	// span {
	//   font-size: 1.25rem;
	//   font-weight: bold;
	//   color: $main-color;
	// }

	ul {
		li {
			font-size: 1rem;
			color: $main-color;
			font-weight: bold;
		}
	}
}

@include to-mobile-sm {
	.projects__room {
		.project__details {
			.project__details--header {
				.project__details-name {
					flex-direction: column;
					align-items: flex-start;
					small {
						margin-top: 1rem;
					}
				}
			}
		}
	}
}
